import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Barcelona - Europe | Kiwi & Cacahuate"
      description="5 week trip to Europe"
      url="https://kiwicacahuate.com/barcelona/"
      image="https://kiwicacahuate.com/barcelona/barcelona.jpg"
      imageWidth="4000"
      imageHeight="2668"
    />

    <div className="">
      <div className="story">
        <div className="photoset-section">
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/barcelona.webp, /barcelona/barcelona.webp 2x" />
                <source srcSet="/barcelona/barcelona.jpg, /barcelona/barcelona.jpg 2x" />
                <img
                  src="/barcelona/barcelona.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/001.webp, /barcelona/thumbnail/001-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/001.jpg, /barcelona/thumbnail/001-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/001.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/002.webp, /barcelona/thumbnail/002-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/002.jpg, /barcelona/thumbnail/002-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/002.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/003.webp, /barcelona/thumbnail/003-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/003.jpg, /barcelona/thumbnail/003-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/003.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/004.webp, /barcelona/thumbnail/004-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/004.jpg, /barcelona/thumbnail/004-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/004.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/005.webp, /barcelona/thumbnail/005-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/005.jpg, /barcelona/thumbnail/005-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/005.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/006.webp, /barcelona/thumbnail/006-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/006.jpg, /barcelona/thumbnail/006-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/006.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/007.webp, /barcelona/thumbnail/007-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/007.jpg, /barcelona/thumbnail/007-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/007.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/008.webp, /barcelona/thumbnail/008-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/008.jpg, /barcelona/thumbnail/008-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/008.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/009.webp, /barcelona/thumbnail/009-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/009.jpg, /barcelona/thumbnail/009-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/009.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/010.webp, /barcelona/thumbnail/010-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/010.jpg, /barcelona/thumbnail/010-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/010.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/011.webp, /barcelona/thumbnail/011-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/011.jpg, /barcelona/thumbnail/011-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/011.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/012.webp, /barcelona/thumbnail/012-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/012.jpg, /barcelona/thumbnail/012-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/012.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/013.webp, /barcelona/thumbnail/013-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/013.jpg, /barcelona/thumbnail/013-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/013.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/014.webp, /barcelona/thumbnail/014-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/014.jpg, /barcelona/thumbnail/014-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/014.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/015.webp, /barcelona/thumbnail/015-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/015.jpg, /barcelona/thumbnail/015-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/015.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/016.webp, /barcelona/thumbnail/016-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/016.jpg, /barcelona/thumbnail/016-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/016.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/017.webp, /barcelona/thumbnail/017-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/017.jpg, /barcelona/thumbnail/017-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/017.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/018.webp, /barcelona/thumbnail/018-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/018.jpg, /barcelona/thumbnail/018-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/018.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/019.webp, /barcelona/thumbnail/019-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/019.jpg, /barcelona/thumbnail/019-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/019.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/020.webp, /barcelona/thumbnail/020-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/020.jpg, /barcelona/thumbnail/020-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/020.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/021.webp, /barcelona/thumbnail/021-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/021.jpg, /barcelona/thumbnail/021-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/021.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/022.webp, /barcelona/thumbnail/022-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/022.jpg, /barcelona/thumbnail/022-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/022.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/023.webp, /barcelona/thumbnail/023-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/023.jpg, /barcelona/thumbnail/023-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/023.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/024.webp, /barcelona/thumbnail/024-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/024.jpg, /barcelona/thumbnail/024-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/024.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/025.webp, /barcelona/thumbnail/025-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/025.jpg, /barcelona/thumbnail/025-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/025.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/026.webp, /barcelona/thumbnail/026-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/026.jpg, /barcelona/thumbnail/026-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/026.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/027.webp, /barcelona/thumbnail/027-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/027.jpg, /barcelona/thumbnail/027-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/027.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/028.webp, /barcelona/thumbnail/028-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/028.jpg, /barcelona/thumbnail/028-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/028.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/029.webp, /barcelona/thumbnail/029-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/029.jpg, /barcelona/thumbnail/029-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/029.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/031.webp, /barcelona/thumbnail/031-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/031.jpg, /barcelona/thumbnail/031-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/031.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/032.webp, /barcelona/thumbnail/032-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/032.jpg, /barcelona/thumbnail/032-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/032.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/033.webp, /barcelona/thumbnail/033-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/033.jpg, /barcelona/thumbnail/033-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/033.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/034.webp, /barcelona/thumbnail/034-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/034.jpg, /barcelona/thumbnail/034-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/034.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/035.webp, /barcelona/thumbnail/035-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/035.jpg, /barcelona/thumbnail/035-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/035.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/036.webp, /barcelona/thumbnail/036-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/036.jpg, /barcelona/thumbnail/036-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/036.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/037.webp, /barcelona/thumbnail/037-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/037.jpg, /barcelona/thumbnail/037-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/037.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/038.webp, /barcelona/thumbnail/038-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/038.jpg, /barcelona/thumbnail/038-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/038.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/039.webp, /barcelona/thumbnail/039-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/039.jpg, /barcelona/thumbnail/039-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/039.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/040.webp, /barcelona/thumbnail/040-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/040.jpg, /barcelona/thumbnail/040-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/040.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/041.webp, /barcelona/thumbnail/041-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/041.jpg, /barcelona/thumbnail/041-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/041.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/042.webp, /barcelona/thumbnail/042-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/042.jpg, /barcelona/thumbnail/042-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/042.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
          <div class="photoset-row">
            <div class="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/barcelona/thumbnail/043.webp, /barcelona/thumbnail/043-2x.webp 2x" />
                <source srcSet="/barcelona/thumbnail/043.jpg, /barcelona/thumbnail/043-2x.jpg 2x" />
                <img
                  src="/barcelona/thumbnail/043.jpg"
                  alt="Kiwi cachuate images"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)
